import { createGlobalStyle, css } from "styled-components"

export type Pallette = typeof palette

export const palette = {
  primary: {
    main: "#FF0D02",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#242424",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#ed4337",
    contrastText: "#FFFFFF",
  },
  background: {
    header: "rgb(255 255 255 / 95%)",
    default: "#FFFFFF",
    paper: "#EEEEEE",
  },
  text: {
    main: "#242424",
    light: "#888888",
    linkHover: "#000000",
    border: "#CFCFCF",
  },
}

export const fonts = {
  light: `"Lexend-Light", sans-serif`,
  medium: `"Lexend-Medium", sans-serif`,
  regular: `"Lexend-Regular", sans-serif`,
  bold: `"Lexend-Bold", sans-serif`,
  semiBold: `"Lexend-SemiBold", sans-serif`,
  extraBold: `"Lexend-ExtraBold", sans-serif`,
}

export const theme = {
  palette,
  fonts,
}

export const themeOptions = {
  pageWidth: "80rem",
  slideWidth: "77rem",
  headerHeight: "5.625rem",
  stickyHeaderHeight: "4.625rem",
  distance: "1.5rem",
  footerDistance: "3rem 1.5rem",
  sectionDistance: "6rem 0",
  radius: "0.6rem",
  transition: "all 0.2s ease-out",
}

export const devicesSizing = {
  xl: 2000,
  lg: 1536,
  res: 1275,
  md: 1024,
  sm: 970,
  xs: 320,
}

export const breakpoints = {
  hdpi: `screen and (min-width: ${devicesSizing.xl}px)`,
  mdpi: `screen and (max-width: ${devicesSizing.lg}px)`,
  res: `screen and (max-width: ${devicesSizing.res}px)`,
  mobile: `screen and (max-width: ${devicesSizing.md}px)`,
  mobile_mdpi: `screen and (max-width: ${devicesSizing.sm}px)`,
  mobile_small: `screen and (max-width: ${720}px)`,
  mobile_ldpi: `screen and (max-width: ${devicesSizing.xs}px)`,
}

type Breakpoint = keyof typeof breakpoints

export const bp =
  (point: Breakpoint) =>
  (...args: any) =>
    css`
      @media ${breakpoints[point]} {
        ${(css as any)(...args)}
      }
    `

const Styles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    word-break: break-word;
    font-size: 1.125rem;
    ${bp("mobile")`font-size: 1rem;`}
    line-height: 1.618;
  }

  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    min-height: 100vh;
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  button, input, textarea {
    outline: none;
    border: none;
    background: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    resize: none;
  }

  html {
    scroll-padding: ${themeOptions.stickyHeaderHeight};
    ${bp("mobile_mdpi")`scroll-padding:0;`};
  }

  html, body, * {
    color: ${({ theme }) => theme.palette.text.main};
    font-weight: 300;
  }

  .main {
    min-height: 70vh;
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: unset;
    transition: ${themeOptions.transition};
    -webkit-tap-highlight-color: transparent;
    width: fit-content;
  }
  
  html, body {
    background: ${({ theme }) => theme.palette.background.default};
    font-size: 16px;

    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  h1 {
    font-size: 2.8rem;
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }
  
  h2 {
    font-size: 2rem;
    font-family: ${({ theme }) => theme.fonts.medium};
  }
  
  h3 {
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  strong {
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }

  a:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  p {
    white-space: pre-line;
  }

  hr {
    height: 1px;
    width: 100%;
    background:${({ theme }) => theme.palette.text.border};
    border: none;
  }

  .Toastify__toast-body > div:last-child {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.medium};
  }
`

export default Styles
