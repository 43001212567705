import styled from "styled-components"
import { themeOptions } from "../theme/Styles"

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${themeOptions.pageWidth};
  padding: 0 ${themeOptions.distance};
  position: relative;
`
