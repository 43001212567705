import React from "react"
import styled from "styled-components"

const Separator: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <>
      <SeparatorContainer {...{ color }} />
    </>
  )
}

const SeparatorContainer = styled.div<{ color?: string }>`
  border-radius: 50%;
  width: 100%;
  height: 0.05rem;
  background-color: ${({ theme, color }) =>
    color ?? theme.palette.primary.main};
`

export default Separator
