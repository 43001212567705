import React, { createContext, RefObject, useState } from "react"
import styled from "styled-components"
import { bp } from "../theme/Styles"
import useBreakpoint from "../utils/use-breakpoint"
import Button from "./Button"
import TextArea from "./TextArea"
import TextField from "./TextField"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const ContactForm: React.FC<{ dark?: boolean }> = ({ dark = false }) => {
  const isMobile = useBreakpoint()

  const [firstname, setFirstname] = useState("")
  const [surname, setSurname] = useState("")
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState(false)

  const send = async (e: React.FormEvent) => {
    e.preventDefault()

    const name = isMobile ? fullName : `${firstname} ${surname}`

    if (!name || !email) {
      setError(true)
      return
    }

    // Perform API call to send form data
    try {
      const response = await fetch("https://api.garage51.de/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message }),
      })

      // Handle the response as needed
      if (response.ok) {
        toast.success("E-Mail erfolgreich gesendet", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        })

        setFullName("")
        setFirstname("")
        setSurname("")
        setEmail("")
        setMessage("")
        setError(false)
      } else {
        // Error handling
        toast.error(
          "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingaben.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        )
        setError(true)
      }
    } catch (error) {
      setError(true)
      console.error("Error occurred:", error)
    }
  }

  return (
    <CFContainer dark={dark ? 1 : 0}>
      <ContactFormHead>
        <h2>Lassen Sie uns ein gemeinsames Projekt starten!</h2>
        <p>
          Sie haben eine Idee für ein Projekt, aber Ihnen fehlt noch der
          richtige Partner?
          <br />
          Schreiben Sie uns und wir besprechen gerne erst einmal unverbindlich
          Ihre Pläne.
        </p>
        <Button
          type="link"
          to="https://calendly.com/garage51/30min?month=2023-09"
          target="_blank"
          title="Termin vereinbaren"
          variant="primary"
        />
      </ContactFormHead>

      <ContactFormGrid>
        <Contact>
          <h3>Kontakt</h3>
          <ul>
            <li>
              <p>T</p> <a href="tel:+496946003299">+49 69 – 46 00 32 99</a>
            </li>
            <li>
              <p>M</p> <a href="mailto:all@garage51.com">all@garage51.com</a>
            </li>
          </ul>
        </Contact>
        <OpeningHours>
          <h3>Öffnungszeiten</h3>
          <p>
            Montag - Freitag
            <br />
            09:00 – 20:00 Uhr
          </p>
        </OpeningHours>
        <ContactFormContainer>
          <h3>Senden Sie uns eine Nachricht</h3>

          <div className="names">
            {isMobile ? (
              <TextField
                name="Name"
                placeHolder="Vor- und Nachname"
                value={fullName}
                onValueChange={v => setFullName(v)}
                error={error && !fullName}
                errorText="Bitte geben Sie Ihre Name an"
              />
            ) : (
              <>
                <TextField
                  value={firstname}
                  name="Name"
                  placeHolder="Vorname"
                  onValueChange={v => setFirstname(v)}
                  error={error && !firstname}
                  errorText="Bitte geben Sie Ihre Vorname an"
                />
                <TextField
                  value={surname}
                  name="Nachname"
                  onValueChange={v => setSurname(v)}
                  error={error && !surname}
                  errorText="Bitte geben Sie Ihre Nachname an"
                />
              </>
            )}
          </div>
          <TextField
            name="E-Mail"
            placeHolder="E-Mail Adresse"
            value={email}
            onValueChange={v => setEmail(v)}
            error={error && !email}
            errorText="Bitte geben Sie eine gültige E-Mail-Adresse an."
          />
          <TextArea
            name="Nachricht"
            placeHolder="Schreib uns eine Nachricht"
            value={message}
            onValueChange={v => setMessage(v)}
          />

          <Button
            title="Absenden"
            variant={dark ? "primary" : "secondary"}
            action={e => send(e as React.FormEvent)}
          />
        </ContactFormContainer>
      </ContactFormGrid>
    </CFContainer>
  )
}

export const ContactFormContext = createContext<{
  openContact: boolean
  setOpenContact: (v: boolean) => void
  fromElement: React.RefObject<HTMLElement> | undefined
  setFromElement: (v?: React.RefObject<HTMLElement> | undefined) => void
}>({
  openContact: false,
  setOpenContact: (v: boolean) => {},
  fromElement: undefined,
  setFromElement: (v?: RefObject<HTMLElement>) => {},
})

const CFContainer = styled.div<{ dark?: number }>`
  ${({ theme, dark }) =>
    dark &&
    `
      padding: 3rem 4rem;
      background-color: ${theme.palette.text.main};

      h2,h3,p,a,label {
        color: ${theme.palette.background.default};
      }

      h3 {
        font-size: 1.25rem !important;
      }
  `}

  ${bp("mobile_mdpi")`padding: 2rem 1rem;`}
`

const ContactFormHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h2 {
    font-size: 2rem;
    line-height: 1.5;
    ${bp("mobile_mdpi")`font-size: 1.5rem;`}
  }
`

const ContactFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  padding-top: 3.5rem;

  ${bp("mobile_mdpi")`
    display: flex;
    flex-direction: column;
    gap: 3rem;
  `}

  h3 {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.palette.text.light};
  }
`

const Contact = styled.div`
  grid-column: 1 / 2;

  h3 {
    padding-bottom: 1.5rem;
  }

  li {
    margin-bottom: 0;
    display: flex;
    gap: 1rem;

    p {
      color: ${({ theme }) => theme.palette.primary.main};
      min-width: 1rem;
    }
    a {
      text-decoration: underline;
    }
  }
`

const OpeningHours = styled.div`
  grid-column: 2 / 3;

  h3 {
    padding-bottom: 1.5rem;
  }
`

const ContactFormContainer = styled.div`
  grid-column: 3 / 5;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .names {
    display: flex;
    gap: 1.5rem;
    ${bp("mobile_mdpi")`flex-direction: column;`}
  }

  .button {
    align-self: flex-end;
  }
`

export default ContactForm
