exports.components = {
  "component---src-layouts-work-tsx": () => import("./../../../src/layouts/Work.tsx" /* webpackChunkName: "component---src-layouts-work-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agency-tsx": () => import("./../../../src/pages/agency.tsx" /* webpackChunkName: "component---src-pages-agency-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-3-d-game-development-tsx": () => import("./../../../src/pages/services/3d-game-development.tsx" /* webpackChunkName: "component---src-pages-services-3-d-game-development-tsx" */),
  "component---src-pages-services-augmented-reality-tsx": () => import("./../../../src/pages/services/augmented-reality.tsx" /* webpackChunkName: "component---src-pages-services-augmented-reality-tsx" */),
  "component---src-pages-services-consultancy-tsx": () => import("./../../../src/pages/services/consultancy.tsx" /* webpackChunkName: "component---src-pages-services-consultancy-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-interaktive-installationen-tsx": () => import("./../../../src/pages/services/interaktive-installationen.tsx" /* webpackChunkName: "component---src-pages-services-interaktive-installationen-tsx" */),
  "component---src-pages-services-mobile-apps-tsx": () => import("./../../../src/pages/services/mobile-apps.tsx" /* webpackChunkName: "component---src-pages-services-mobile-apps-tsx" */),
  "component---src-pages-services-motion-design-tsx": () => import("./../../../src/pages/services/motion-design.tsx" /* webpackChunkName: "component---src-pages-services-motion-design-tsx" */),
  "component---src-pages-services-web-development-tsx": () => import("./../../../src/pages/services/web-development.tsx" /* webpackChunkName: "component---src-pages-services-web-development-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

