import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Logo from "../assets/icons/Logo.svg"
import Line from "../assets/icons/Icn_Line.svg"
import { bp, themeOptions } from "../theme/Styles"
import { Container } from "./Container"
import Button from "./Button"
import useBreakpoint from "../utils/use-breakpoint"
import BurgerMenuIcon from "./BurgerMenuIcon"
import { motion } from "framer-motion"
import { useDimensions } from "../utils/use-dimensions"
import { PreventBodyScroll } from "../layouts"
import Separator from "./Separator"
import { copyRightLinks } from "./Footer"
import useScrollPosition from "../utils/use-scroll-position"
import { ContactFormContext } from "./ContactForm"
import FadeLink from "./FadeLink"

interface NavigationItem {
  text: string
  path: string
  icon?: React.ReactElement
}

export const navigationItems: Array<NavigationItem> = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "Agency",
    path: "/agency",
  },
  {
    text: "Work",
    path: "/work",
  },
  {
    text: "Services",
    path: "/services",
  },
]

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 1.5 + 200}px at 88% 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0 at 88% 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
}

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const Navigation: React.FC<{ path: string }> = ({ path }) => {
  const isMobile = useBreakpoint()
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition()
  const { height } = useDimensions(containerRef)

  const from = useRef<HTMLElement | null>(null)

  const { setOpenContact, setFromElement } = useContext(ContactFormContext)

  const [isOpen, setOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)

  const pathname = "/" + path.split("/")[1]

  useEffect(() => {
    if (scrollPosition > 0) setIsSticky(true)
    else setIsSticky(false)
  }, [scrollPosition])

  return (
    <NavigationContainer sticky={isMobile ? 1 : 0}>
      {isOpen && <PreventBodyScroll />}
      <NavigationContainerInner sticky={!isMobile && isSticky ? 1 : 0}>
        <LogoContainer to="/" title="Startseite" onClick={() => setOpen(false)}>
          <Logo />
          <span>Garage 51</span>
        </LogoContainer>

        {isMobile ? (
          <>
            <BurgerMenuIcon open={isOpen} onClick={() => setOpen(!isOpen)} />
            <MotionNav
              initial={false}
              animate={isOpen ? "open" : "closed"}
              custom={height}
              ref={containerRef}
              open={isOpen}
            >
              <motion.div className="background" variants={sidebar} />
              <MobileNavigation
                onClose={() => setOpen(false)}
                pathname={pathname}
                openPopup={e => {
                  setOpenContact(true)
                  from.current = e.target as HTMLElement
                  setFromElement(from)
                  setOpen(false)
                }}
              />
            </MotionNav>
          </>
        ) : (
          <MenuContainer>
            <ListContainer>
              {navigationItems.map((item, idx) => (
                <ListItem key={idx}>
                  <FadeLink
                    to={item.path}
                    title={item.text}
                    className={pathname === item.path ? "active" : ""}
                  >
                    <span>{item.text}</span>
                  </FadeLink>
                </ListItem>
              ))}
            </ListContainer>
            <Button
              title="Kontakt aufnehmen"
              variant="primary"
              action={e => {
                setOpen(false)
                setOpenContact(true)
                setFromElement(from)
                from.current = e?.target as HTMLElement
              }}
            />
          </MenuContainer>
        )}
      </NavigationContainerInner>
    </NavigationContainer>
  )
}

const MobileNavigation: React.FC<{
  onClose: () => void
  pathname: string
  openPopup: (e: Event) => void
}> = ({ onClose, pathname, openPopup }) => (
  <MobileNavigationContainer variants={variants}>
    <MobileMenuContainer>
      {navigationItems.map((item, idx) => (
        <MobileNavItem
          variants={itemVariants}
          whileTap={{ scale: 0.95 }}
          key={idx}
        >
          <FadeLink
            to={item.path}
            title={item.text}
            onClick={() => onClose()}
            className={pathname === item.path ? "active" : ""}
          >
            <span>{item.text}</span>
          </FadeLink>
        </MobileNavItem>
      ))}

      <MobileButton variants={itemVariants} className="contact">
        <Button
          title="Kontakt aufnehmen"
          variant="primary"
          action={e => openPopup(e as Event)}
        />
      </MobileButton>

      <motion.li variants={itemVariants}>
        <Separator />
      </motion.li>

      <MobileFooter variants={itemVariants} className="copy-right">
        <CopyRightContainer>
          <p>
            © 2023 by Garage 51 GmbH. <br />
            All rights reserved.
          </p>
          <div>
            {copyRightLinks.map((item, idx) => (
              <div key={idx}>
                <FadeLink
                  key={idx}
                  to={item.link}
                  title={item.name}
                  onClick={() => onClose()}
                >
                  {item.name}
                </FadeLink>
                {idx + 1 < copyRightLinks.length && <Line />}
              </div>
            ))}
          </div>
        </CopyRightContainer>
      </MobileFooter>
    </MobileMenuContainer>
  </MobileNavigationContainer>
)

const MobileNavigationContainer = styled(motion.ul)`
  position: relative;
  height: 100%;
  margin-top: 15vh;
  overflow: auto;
`

const MotionNav = styled(motion.nav)<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: ${themeOptions.distance};
  width: 100%;
  height: 100%;
  // -webkit-transition: opacity 0.2s ease-out;
  // transition: opacity 0.2s ease-out;
  z-index: -1;
  transition: z-index 5s;

  ${({ open }) => open && `z-index: 2;transition: z-index 0s;`}

  .background {
    background-color: ${({ theme }) => theme.palette.background.default};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`

const MobileMenuContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.8rem;
`

const MobileNavItem = styled(motion.li)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 2rem;
  
  a {
    span {
      font-family: ${({ theme }) => theme.fonts.medium};
      position: relative;
      font-size: 2rem;

      &:after {
        background: ${({ theme }) => theme.palette.primary.main};
        content: "";
        width: 0%;
        height: 0.25rem;
        border-radius: 4px;
        position: absolute;
        bottom: -0.4rem;
        left: 50%;
        transform: translateX(-50%);
        transition: ${themeOptions.transition};
      }
    }

  }
  a:hover,
  .active {
    span {
      &:after {
        width: 100%;
      }
  }
`

const MobileFooter = styled(motion.li)`
  margin-bottom: 2rem;
`

const MobileButton = styled(motion.li)`
  margin: 2rem 0;
  font-size: 1rem;
`

const LogoContainer = styled(FadeLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  z-index: 3;

  span {
    font-family: ${({ theme }) => theme.fonts.extraBold};
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  svg {
    width: 3.5rem;
    height: 3.5rem;
    transition: ${themeOptions.transition};

    ${bp("mobile_mdpi")`
      width: 2.5rem;
      height: 2.5rem;
    `}
  }
`

const ListItem = styled.li`
  display: flex;

  a {
    padding: 0.5rem 1rem;

    span {
      font-family: ${({ theme }) => theme.fonts.medium};
      position: relative;

      &:after {
        background: ${({ theme }) => theme.palette.primary.main};
        content: "";
        width: 0%;
        height: 0.25rem;
        border-radius: 4px;
        position: absolute;
        bottom: -0.4rem;
        left: 50%;
        transform: translateX(-50%);
        transition: ${themeOptions.transition};
      }
    }

  }
  a:hover,
  .active {
    span {
      &:after {
        width: 100%;
      }
  }
`

const NavigationContainer = styled.header<{ sticky: number }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.palette.background.header};
  ${({ sticky }) =>
    !sticky &&
    `
      position: sticky;
      top: 0;
      z-index: 2;
      backdrop-filter: brightness(150%) saturate(100%) blur(0.5rem);
      -webkit-backdrop-filter: brightness(150%) saturate(100%) blur(0.5rem);
  `}
`

const NavigationContainerInner = styled(Container)<{ sticky: number }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  transition: ${themeOptions.transition};
  height: ${({ sticky }) =>
    sticky === 1 ? themeOptions.stickyHeaderHeight : themeOptions.headerHeight};
  position: inherit;

  ${LogoContainer} {
    ${({ sticky }) =>
      sticky &&
      `
      svg {
        width: 3rem;
        ${bp("mobile_mdpi")`;width: 2.5rem;`}
      }
      `}
  }

  ${ListItem} {
    ${({ sticky }) =>
      sticky &&
      `
        span:after {
          height: 0.2rem;
          bottom: -0.35rem;
        }
  `}
  }
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  ${bp("mobile_mdpi")`display: none;`};
`

const ListContainer = styled.ul`
  display: flex;
`

const CopyRightContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 0.8rem;

  div {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`

export default Navigation
