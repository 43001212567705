import React from "react"
import styled from "styled-components"

const BurgerMenuIcon: React.FC<{ open: boolean; onClick: () => void }> = ({
  open,
  onClick,
}) => {
  return (
    <NavContainer {...{ open, onClick }}>
      <div className="bar" />
    </NavContainer>
  )
}

const NavContainer = styled.div<{ open: boolean }>`
  cursor: pointer;
  position: relative;
  width: 3rem;
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.palette.text.light};
  border-radius: 50%;
  display: flex;
  z-index: 3;
  -webkit-tap-highlight-color: transparent;

  .bar,
  .bar:before,
  .bar:after {
    position: absolute;
    background-color: ${({ theme }) => theme.palette.text.main};
    width: 1.2rem;
    height: 2px;
    content: "";
  }

  .bar {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background 0.3s cubic-bezier(1, -0.5, 0, 1.6);

    &:before {
      bottom: -5px;
      transition: bottom 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
        transform 0.45s cubic-bezier(0.23, 1, 0.32, 1),
        -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:after {
      top: -5px;
      transition: top 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
        transform 0.45s cubic-bezier(0.23, 1, 0.32, 1),
        -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
    }

    ${({ open }) =>
      open &&
      `
        background: hsla(0, 0%, 100%, 0);
        transition: all 0.1s;

        &:before {
          bottom: 0;
          transform: rotate(-45deg);
          transition: bottom 0.25s cubic-bezier(0.23, 1, 0.32, 1),
            transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
            -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
        }

        &:after {
          top: 0;
          transform: rotate(45deg);
          transition: top 0.25s cubic-bezier(0.23, 1, 0.32, 1),
            transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
            -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
        }
    `}
  }
`

export default BurgerMenuIcon
