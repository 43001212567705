import React, { useId } from "react"
import styled from "styled-components"
import { themeOptions } from "../theme/Styles"

type TextFieldProps = {
  name: string
  value: string
  placeHolder?: string
  error: boolean
  errorText?: string
  onValueChange: (v: string) => void
}

const TextField: React.FC<TextFieldProps> = ({
  name,
  value,
  placeHolder,
  error,
  errorText,
  onValueChange,
}) => {
  const id = useId()

  return (
    <TextFieldContainer>
      <label htmlFor={id}>{name}</label>
      <input
        value={value}
        type="text"
        name={name ?? placeHolder}
        id={id}
        placeholder={placeHolder ?? name}
        onChange={e => onValueChange(e.target.value)}
      />
      {error && <span>{errorText}</span>}
    </TextFieldContainer>
  )
}

const TextFieldContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  position: relative;
  width: 100%;

  label {
    font-family: ${({ theme }) => theme.fonts.medium};
  }

  input {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.background.paper};
    font-family: ${({ theme }) => theme.fonts.regular};
    background: #fff;
    font-size: 1.125rem;
    padding: 0.7rem 0.9rem;
    border-bottom: 3px solid transparent;
    transition: ${themeOptions.transition};

    &:focus {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0.2s;
  }

  span {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.error.main};
    font-size: 0.78rem;
    position: absolute;
    top: 0.2rem;
    right: 0;
    padding: 0.12rem 0.6rem;
    border-radius: 7px;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      right: 5%;
      border-width: 5px;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.primary.main} transparent
        transparent transparent;
    }
  }
`

export default TextField
