import React, { createContext, RefObject } from "react"
import styled from "styled-components"
import { bp } from "../theme/Styles"
import Button from "./Button"
import Checkbox from "./Checkbox"

const CookieSetting: React.FC<{
  dark?: boolean
  onAnalytics: (e: boolean) => void
  onAccepted: () => void
}> = ({ dark = false, onAccepted, onAnalytics }) => {
  return (
    <CFContainer dark={dark ? 1 : 0}>
      <CookieSettingHead>
        <h2>Cookie Einstellungen</h2>
        <p>
          Wir verwenden Cookies zur automatisierten Datenerfassung. Einige
          dieser Cookies sind für den Betrieb notwendig und können nicht
          deaktiviert werden. Andere nicht notwendige Cookies helfen uns, den
          Erfolg unserer Marketingaktivitäten zu messen, die Nutzung zu
          analysieren und dieses für Sie noch passgenauer zu gestalten.
        </p>
      </CookieSettingHead>

      <CookieSettingContent>
        <Checkbox defaultChecked={true} disabled={true}>
          Notwendigen Cookies
        </Checkbox>
        <Checkbox defaultChecked={true} onChange={e => onAnalytics(e)}>
          Google Analytics
        </Checkbox>
      </CookieSettingContent>

      <CookieSettingAction>
        <Button
          title="Akzeptieren"
          action={() => onAccepted()}
          variant="primary"
        />
      </CookieSettingAction>
    </CFContainer>
  )
}

export const CookieSettingContext = createContext<{
  openContact: boolean
  setOpenContact: (v: boolean) => void
  fromElement: React.RefObject<HTMLElement> | undefined
  setFromElement: (v?: React.RefObject<HTMLElement> | undefined) => void
}>({
  openContact: false,
  setOpenContact: (v: boolean) => {},
  fromElement: undefined,
  setFromElement: (v?: RefObject<HTMLElement>) => {},
})

const CFContainer = styled.div<{ dark?: number }>`
  max-width: 50rem;
  padding: 3rem 4rem;
  background-color: ${({ theme }) => theme.palette.background.default};

  h3 {
    font-size: 1.25rem !important;
  }

  ${({ theme, dark }) =>
    dark &&
    `
      background-color: ${theme.palette.text.main};

      h2,h3,p,a,label {
        color: ${theme.palette.background.default};
      }

  `}

  ${bp("mobile_mdpi")`padding: 2rem 1rem;`}
`

const CookieSettingHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h2 {
    font-size: 2rem;
    line-height: 1.5;
    ${bp("mobile_mdpi")`font-size: 1.5rem;`}
  }
`

const CookieSettingContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
`

const CookieSettingAction = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: flex-end;
`

export default CookieSetting
