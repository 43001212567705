import React from "react"
import styled from "styled-components"
import { ButtonContainer } from "./Button"
import { Container } from "./Container"
import { navigationItems } from "./Navigation"
import Linkedin from "../assets/icons/Icn_linkedin.svg"
import Vimeo from "../assets/icons/Icn_Vimeo.svg"
import Instagram from "../assets/icons/Icn_Instagram.svg"
import Line from "../assets/icons/Icn_Line.svg"
import { bp, themeOptions } from "../theme/Styles"
import useBreakpoint from "../utils/use-breakpoint"
import Separator from "./Separator"
import { OutboundLink } from "gatsby-plugin-google-gtag-optin"
import FadeLink from "./FadeLink"

const followUs = [
  {
    link: "https://www.linkedin.com/company/garage51-gmbh",
    name: "LinkedIn",
    icon: <Linkedin />,
    color: "#0966c2",
  },
  {
    link: "https://www.instagram.com/garage51.de",
    name: "Instagram",
    icon: <Instagram />,
    color: "#db2c7b",
  },
  {
    link: "https://vimeo.com/garage51",
    name: "Vimeo",
    icon: <Vimeo />,
    color: "#86c9ef",
  },
]

export const copyRightLinks = [
  { link: "/impressum", name: "Impressum" },
  { link: "/datenschutz", name: "Datenschutz" },
]

const Footer = () => {
  const isMobile = useBreakpoint()

  return (
    <FooterContainer>
      <ContactContainer>
        <div>
          {!isMobile && <p>Vielen dank fürs Scrollen!</p>}
          <p>
            Wir freuen uns über Ihre <span>Nachricht</span>
          </p>
        </div>

        <div>
          <IconButton
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            variant="primary"
          >
            <span>Hoch</span>
          </IconButton>
        </div>
      </ContactContainer>

      <Container>
        <Separator />
      </Container>

      <LinksContainer>
        <Navigation>
          <h3>Navigation</h3>
          <ul>
            {navigationItems.map((item, idx) => (
              <li key={idx}>
                <FadeLink to={item.path} title={item.text}>
                  {item.text}
                </FadeLink>
              </li>
            ))}
          </ul>
        </Navigation>

        <Contact>
          <h3>Kontakt</h3>
          <ul>
            <li>
              <p>T</p> <a href="tel:+496946003299">+49 69 – 46 00 32 99</a>
            </li>
            <li>
              <p>M</p> <a href="mailto:all@garage51.com">all@garage51.com</a>
            </li>
          </ul>
        </Contact>

        <div>
          <h3>Adresse</h3>
          <p>
            GARAGE 51 GmbH
            <br />
            Daimlerstraße 32
            <br />
            60314 Frankfurt
            <br />
            Germany
          </p>
        </div>

        <FollowUS>
          <h3>Folge uns</h3>
          <div>
            {followUs.map((item, idx) => (
              <FolowLink
                key={idx}
                href={item.link}
                title={`Folge uns auf ${item.name}`}
                target="_blank"
                rel="nofollow"
                color={item.color}
              >
                {item.icon}
              </FolowLink>
            ))}
          </div>
        </FollowUS>
      </LinksContainer>

      <Container>
        <Separator />
      </Container>

      <CopyRightContainer>
        <p>
          © {new Date().getFullYear()} by Garage 51 GmbH. {isMobile && <br />}
          All rights reserved.
        </p>
        <div>
          {copyRightLinks.map((item, idx) => (
            <div key={idx}>
              <FadeLink key={idx} to={item.link} title={item.name}>
                {item.name}
              </FadeLink>
              {idx + 1 < copyRightLinks.length && <Line />}
            </div>
          ))}
        </div>
      </CopyRightContainer>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.text.main};
  color: ${({ theme }) => theme.palette.background.default};
`

const ContactContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: ${themeOptions.footerDistance};
  gap: 2rem;
  align-items: center;

  p,
  p span {
    color: ${({ theme }) => theme.palette.background.default};
    font-size: 2rem;
    ${bp("mobile")`font-size: 1.5rem;`}

    span {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

const CopyRightContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: ${themeOptions.footerDistance};
  ${bp("mobile")`flex-direction: column;`}
  gap: 2rem;

  p,
  a,
  span {
    color: ${({ theme, color }) => color ?? theme.palette.background.default};
  }

  a:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  div {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`

const FolowLink = styled(OutboundLink)<{ color: string }>`
  svg {
    width: 2rem;
    height: 2rem;

    path {
      transition: ${themeOptions.transition};
    }

    &:hover path {
      fill: ${({ theme, color }) => color ?? theme.palette.background.default};
    }
  }
`

const LinksContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: ${themeOptions.footerDistance};
  gap: 2rem;

  ${bp("mobile")`flex-direction: column;`}

  h3 {
    color: ${({ theme }) => theme.palette.text.light};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  li {
    color: ${({ theme }) => theme.palette.background.default};
    margin-bottom: 0.5rem;

    span {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  p {
    color: ${({ theme }) => theme.palette.background.default};
    margin-bottom: 0.5rem;
  }
`

const Navigation = styled.div`
  li {
    color: ${({ theme }) => theme.palette.background.default};
    margin-bottom: 0.5rem;
  }
`

const FollowUS = styled.div`
  div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`

const Contact = styled.div`
  li {
    color: ${({ theme }) => theme.palette.background.default};
    margin-bottom: 0;
    display: flex;
    gap: 1rem;

    p {
      color: ${({ theme }) => theme.palette.primary.main};
      min-width: 1rem;
    }
    a {
      text-decoration: underline;
    }
  }
`

const IconButton = styled(ButtonContainer)`
  height: 6rem;
  width: 6rem;
  justify-content: center;
  border-radius: 50%;
`

export default Footer
