import React from "react"
import styled, { css } from "styled-components"

interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLLabelElement>, "onChange"> {
  onChange?: (c: boolean) => void
  defaultChecked?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
  style,
  className,
  children,
  onChange,
  defaultChecked,
  checked,
  disabled,
}) => {
  return (
    <CheckboxContainer {...{ style, className, disabled }}>
      {children}

      <input
        type="checkbox"
        onChange={e => onChange?.(e.target.checked)}
        {...{ checked, defaultChecked, disabled }}
      />

      <span className="checkmark" />
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.label<{
  disabled?: boolean
}>`
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  height: 17px;
  line-height: 14px;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      filter: brightness(60%);
    `}

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;

    :hover {
      filter: brightness(85%);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 17px;
    width: 17px;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 3px;
    transition: all 0.1s linear;
    transition-property: background-color border-color;
  }

  input:checked ~ .checkmark {
    background: ${({ theme }) => theme.palette.primary.main};
    filter: brightness(85%);
  }

  a:hover ~ .checkmark {
    background: ${({ theme }) => theme.palette.background.default} !important;
  }

  :hover input ~ .checkmark {
    filter: brightness(85%);
    background: ${({ theme }) => theme.palette.primary.main};
  }

  :hover input:checked ~ .checkmark {
    background: ${({ theme }) => theme.palette.primary.main};
    filter: brightness(85%);
  }
`

export default Checkbox
