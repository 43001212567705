import React, { RefObject, useState } from "react"
import { PageProps } from "gatsby"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Styles, { theme } from "../theme/Styles"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import { motion } from "framer-motion"
import ContactForm, { ContactFormContext } from "../components/ContactForm"
import Modal from "../components/Modal"
import CookiesBanner from "../components/CookiesBanner"
import "../theme/fonts/fonts.css"
import { ToastContainer } from "react-toastify"
import { ParallaxProvider } from "react-scroll-parallax"
import CookieSetting from "../components/CookieSetting"
import Cookies from "js-cookie"
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin"

const Page: React.FC<PageProps> = ({ children, location }) => {
  const [fromElement, setFromElement] = useState<React.RefObject<HTMLElement>>()
  const [openContact, setOpenContact] = useState(false)
  const [openSetting, setOpenSetting] = useState(false)
  const [analytics, setAnalytics] = useState(true)

  const acceptCookies = () => {
    // Set a cookie to remember that the user has accepted cookies
    Cookies.set("accept_cookies", "true", { expires: 30 }) // Cookie expires in 1 month
    analytics && localStorage.setItem(GTAG_OPTIN_KEY, true as any)
    setOpenSetting(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <ContactFormContext.Provider
          value={{ openContact, setOpenContact, fromElement, setFromElement }}
        >
          <Styles />
          <Navigation path={location.pathname} />

          <motion.div
            className="main"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {children}
          </motion.div>

          <Modal
            showBackdrop
            isShown={openContact}
            fromElement={
              fromElement as RefObject<HTMLElement | null> | undefined
            }
            onClose={() => setOpenContact(false)}
          >
            <ContactForm dark />
          </Modal>

          <Modal
            showBackdrop
            isShown={openSetting}
            fromElement={
              fromElement as RefObject<HTMLElement | null> | undefined
            }
            onClose={() => setOpenSetting(false)}
            showCloseButton={false}
            disableOutsideClick
          >
            <CookieSetting
              onAccepted={acceptCookies}
              onAnalytics={e => setAnalytics(e)}
              dark
            />
          </Modal>

          <Footer />
          <CookiesBanner onSetting={() => setOpenSetting(true)} />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
          />
        </ContactFormContext.Provider>
      </ParallaxProvider>
    </ThemeProvider>
  )
}

export const PreventBodyScroll = createGlobalStyle<{ isAnimating?: boolean }>`
   html {
    overflow: hidden;
    
    ${props => props.isAnimating && `pointer-events: none;`}
  }

`

export default Page
